import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import itLocale from "date-fns/locale/it";
import Footer from "../Footer/Footer";
import { ProductMini } from "../Product/Product";
import Slider from "../Slider/Slider";
import Topbar from "../Topbar/Topbar";
import PostListing from "../PostListing/PostListing";
import { CategorySEO } from "../SEO/SEO";
import { H1, H2, linkStyle } from "../Typo/Typo";
import { getTheme } from "../../utilities";

const Wrapper = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.75;
  margin: auto; // IE
  margin: calc(var(--gutter) * 2) auto 0;
  max-width: 1200px; // IE
  max-width: var(--large-width);
  word-spacing: 0;
  padding: 10px; // IE

  @supports (--var: 0) {
    padding: 0;
  }
`;

const Excerpt = styled.div`
  max-width: calc(var(--content-width) + var(--gutter) * 2);
  margin: 0 auto;
  padding: 0 var(--gutter);
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    ${linkStyle};
  }

  > p {
    margin: 10px auto;
  }
`;

const Meta = styled.div`
  text-align: center;
  font-size: 13px;
  padding: 0 var(--gutter);
  margin-bottom: var(--gutter);
`;

const Title = styled(H2)`
  margin-bottom: 0;
`;

const A = styled.a`
  text-align: center;
  font-size: 13px;
  color: currentColor;
  display: block;
`;

export default class CategoryPage extends React.Component {
  render() {
    const {
      posts,
      postsByCategory,
      category,
      categories,
      parentCategory,
      lastUpdate
    } = this.props;

    return (
      <div className="index-container">
        <Helmet
          title={category.metaTitle ? category.metaTitle : category.title}
        >
          <meta name="theme-color" content={getTheme(category.slug).main} />
        </Helmet>
        <CategorySEO
          title={category.metaTitle || category.title}
          abstract={category.abstract}
          slug={category.slug}
          posts={posts}
          parent={parentCategory}
        />
        <Topbar
          category={parentCategory || category}
          subcategory={parentCategory && category}
          categories={categories.edges}
          sharePath={
            parentCategory
              ? `/${parentCategory.slug}/${category.slug}/`
              : `/${category.slug}/`
          }
          shareTitle={category.metaTitle || category.title}
        />
        <Wrapper
          className={parentCategory ? parentCategory.slug : category.slug}
        >
          <H1>{category.title}</H1>
          <Meta>
            {`Ultimo aggiornamento `}
            <time dateTime={lastUpdate}>
              {formatDistanceToNow(lastUpdate, {
                addSuffix: true,
                locale: itLocale
              })}
            </time>
          </Meta>
          <Excerpt dangerouslySetInnerHTML={{ __html: category.description }} />
          <Title>Le migliori offerte</Title>
          <Slider
            products={posts.map(e => e.node.topProduct).filter(e => e !== null)}
            renderProd={p => (
              <ProductMini key={p.id} product={p} scope="category-slider" />
            )}
          />
          {postsByCategory.map(c => (
            <React.Fragment key={c.slug + c.title}>
              <Title>{c.title}</Title>
              {c.slug ? <A href={`${c.slug}/`}>Tutti gli articoli</A> : null}
              <PostListing posts={c.posts} />
            </React.Fragment>
          ))}
        </Wrapper>
        <Footer />
      </div>
    );
  }
}
