import React from "react";
import max from "date-fns/max";
import { graphql } from "gatsby";
import CategoryPage from "../components/CategoryPage/CategoryPage";
import Layout from "../components/layout";

export default class SubcategoryTemplate extends React.Component {
  render() {
    const posts = this.props.data.posts ? this.props.data.posts.edges : [];
    const { subcategory, categories } = this.props.data;
    const lastUpdate = max(posts.map(post => new Date(post.node.dateModified)));

    const postsByCategory = [
      {
        title: "Tutti gli articoli",
        posts
      }
    ];

    return (
      <Layout>
        <CategoryPage
          categories={categories}
          category={subcategory}
          parentCategory={subcategory.parentCategory}
          posts={posts}
          postsByCategory={postsByCategory}
          lastUpdate={lastUpdate}
        />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SubcategoryPostBySlug($slug: String!) {
    posts: allCrossCastPost(
      limit: 2000
      sort: { fields: [dateModified], order: DESC }
      filter: { subcategory: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          topProduct {
            id
            title
            imageId
            price
            cta
            ctaLabel
          }
          slug
          title
          category {
            title
            slug
          }
          coverId
          coverAlt
          coverTitle
          abstract
          dateModified
        }
      }
    }
    subcategory: crossCastCategory(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      abstract
      description
      parentCategory {
        slug
        title
      }
    }
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
